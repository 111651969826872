<template>
	<div class="journal-table position-relative w-100 h-100 px-4 py-3 d-flex flex-column overflow-hidden">
		<table-summary title="Journal Entry" filter="jeNo" sort="updatedAt"
		               v-bind:order="-1"
		               v-bind:fields="fields"
		               v-bind:data-array="journals"
		               v-on:add-new="addJournal"
		               v-on:row-clicked="navigate($event)">
        </table-summary>
	</div>
</template>

<script>
import TableSummary from "@/components/functional/table/TableSummary";
import {format} from "date-fns";

export default {
	name: "JournalTable",
	
	components: {
		TableSummary,
	},
	
	data() {
		return {
			fields: [
                { display: "Journal No.", key: "jeNo", classBinding: ["f-3"] },
				{ display: "Particulars", key: "descriptions", classBinding: [] },
				{ display: "Sum", key: "sum", classBinding: ["f-3"] },
                { display: "Updated At", key: "updatedAt", classBinding: ["f-3"] },
			],
		}
	},
	
	computed: {
		journals() {
			return this.$store.state.contents.journalEntries.map(journal => {
				const obj = {...journal};
				obj.debits = journal.debits.length;
				obj.credits = journal.credits.length;
				obj.updatedAt = journal.updatedAt ? format(new Date(journal.updatedAt), "yyyy-MM-dd") : "N/A";
				return obj;
			});
		},
	},
	
	methods: {
		async addJournal() {
			await this.$router.push("/accounting/journal/add");
		},
		
		async navigate(dataObj) {
			await this.$router.push(`/accounting/journal/${dataObj._id}`);
		}
	}
}
</script>

<style lang="scss" scoped>

</style>
